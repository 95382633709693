import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../views/index.vue";
import Info from "../components/info.vue";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/newsManagement",
    name: "NewsManagement",
    component: () => import("../views/NewsManagementPage.vue"),
  },
  {
    path: "/logIn",
    name: "LogIn",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/info",
    name: "info",
    component: Info,
  },
  {
    path:"/refresh",
    name:'refresh',
    component:()=>import("../views/refresh.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const user = store.state.userInfo.token
  if (!user && to.path === '/newsManagement'||to.path === '/NewsManagement') {
    return next('/login')
  }
  next()
})
export default router;
