<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
*{
  margin:0;
  padding:0;
}
*::-webkit-scrollbar
  {
      width: 0px;
      height: 8px;
      background-color: rgb(211, 25, 25);
      display: none;
  }
 
  /*定义滚动条轨道 内阴影+圆角*/
  *::-webkit-scrollbar-track
  {
     border-radius: 10px;
     background-color: transparent;
  }
 
  /*定义滑块 内阴影+圆角*/
  *::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      background-color: transparent;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  height: 100vh;
}
</style>
