// src/store/index.js
import {createStore} from 'vuex'

export default createStore({
  state: {
    count: 0,
    userInfo:JSON.parse(localStorage.getItem("userInfo")) || "", //登录者信息,
  },
  mutations: {
    increment(state) {
      state.count++
    },
    setUserInfo(state,userInfo){
      state.userInfo = userInfo
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    }
  },
  actions: {
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit('increment')
      }, 1000)
    }
  },
  getters: {
    doubleCount: state => state.count * 2
  }
})
