import axios from "axios";

var instance = axios.create({
  // baseURL:'https://ocare-test.jy-meditech.com:8010',//测试服务器地址
  baseURL:'https://jy-meditech.com',//线上服务器地址
  timeout: 5000 * 10,
});

instance.interceptors.request.use(
  (config) => {
    const { token } = window.sessionStorage.getItem("user")
      ? JSON.parse(window.sessionStorage.getItem("user"))
      : "";
    const timestamp = new Date().getTime();
    config.headers.timestamp = timestamp;
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default instance;

// /**
//  * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
//  * 函数的参数及返回值如下：
//  * @param {String} method  请求的方法：get、post、delete、put
//  * @param {String} url     请求的url:
//  * @param {Object} data    请求的参数
//  * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
//  */
//  export default (url, method, headers, submitData) => {
//     console.log(url, method, headers, submitData);
//     return instance({
//         url,
//         method,
//         headers,
//         // 1. 如果是get请求  需要使用params来传递data   ?a=10&c=10
//         // 2. 如果不是get请求  需要使用data来传递data   请求体传参
//         // [] 设置一个动态的key, 写js表达式，js表达式的执行结果当作KEY
//         // method参数：get,Get,GET  转换成小写再来判断
//         // 在对象，['params']:data ===== params:data 这样理解
//         [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
//       })
// }
