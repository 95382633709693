import request from '@/service/http.js'

export function getNews(params){
    return request({url:'/scoffice/scoAds/getNews',method:'get', params})
    // return request({url:'/api/scoffice/scoAds/getNews',method:'get', params})
}

export function submitInfo(data){
    return request({url:'/scoffice/connInfo/send',method:'post', data})
    // return request({url:'/api/scoffice/connInfo/send',method:'post', data})
}


// 新聞管理平台

// 登陸
export function login(data){
    // return request({url:'/api/scoffice/user/login',method:'post',data})
    return request({url:'/scoffice/user/login',method:'post',data})
}

// 1、獲取新聞列表
export function getAllNews(params){
    return request({url:'/scoffice/scoAds/getAllPho',method:'get',params})
    // return request({url:'/api/scoffice/scoAds/getAllPho',method:'get',params})
}

// 2、刪除新聞
export function deleteNews(data){
    return request({url:'/scoffice/scoAds/deleteP',method:'post',data})
    // return request({url:'/api/scoffice/scoAds/deleteP',method:'post',data})
}

// 3、上傳圖片
export function uploadFile(data){
    return request({url:'/scoffice/scoAds/uploadFile',method:'post',data,header:{'Content-Type': 'multipart/form-data',}})
    // return request({url:'/api/scoffice/scoAds/uploadFile',method:'post',data,header:{'Content-Type': 'multipart/form-data',}})
}
// 4、添加新聞
export function addNews(data){
    return request({url:'/scoffice/scoAds/addNews',method:'post',data})
    // return request({url:'/api/scoffice/scoAds/addNews',method:'post',data})
}
// 4、修改新聞
export function updateNews(data){
    return request({url:'/scoffice/scoAds/update',method:'post',data})
    // return request({url:'/api/scoffice/scoAds/update',method:'post',data})
}


export function sendSms(data){
    return request({url:'/scoffice/user/sendSms',method:'post',data})
    // return request({url:'/api/scoffice/user/sendSms',method:'post',data})
}

export function loginSms(data){
    return request({url:'/scoffice/user/codeValid',method:'post',data})
    // return request({url:'/api/scoffice/user/codeValid',method:'post',data})
}


// 輪播圖管理
// 1、獲取輪播圖列表
export function getBanner(){
    return request({url:'/scoffice/scoAds/scanner',method:'get'})
    // return request({url:'/api/scoffice/scoAds/scanner',method:'get'})
}
// 2.修改輪播圖信息
export function updateBanner(data){
    return request({url:'/scoffice/scoAds/updateSca',method:'post',data})
    // return request({url:'/api/scoffice/scoAds/updateSca',method:'post',data})
}