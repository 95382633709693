import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/styles/common.less";
import "@/assets/fontfamily/font.css"; //字体
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhTw from "element-plus/es/locale/lang/zh-tw.mjs";

import "default-passive-events";

const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(ElementPlus, { size: "small", zIndex: 3000, locale: zhTw })
  .mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import { debounce } from "lodash";
const resizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends resizeObserver {
  constructor(callback) {
    callback = debounce(callback, 100);
    super(callback);
  }
};
