<template>
  <div class="website">
    <div class="headerH" id="headerH" :style="style">
      <div class="flexCenter flewWidth">
        <div class="left-H"><img :src="logo" alt="" /></div>
        <div class="right-H" v-if="isPhone === false">
          <ul>
            <li @click="scrollToAnchor('contentjy')">走進疆域</li>
            <li @click="scrollToAnchor('product')">產品及解决方案</li>
            <li @click="scrollToAnchor('newsCenter')">新聞中心</li>
            <li @click="scrollToAnchor('footerF')">聯繫我們</li>
          </ul>
        </div>
        <div class="right-H" v-else>
          <img style="width:36px;height:36px;" :src="propImg" v-if="dialogHeaderVisible === false" alt=""
            @click="handleHeaderTable()" />
          <img style="width:36px;height:36px;" :src="propCloseImg" v-else alt="" @click="handleCloseFun()" />
        </div>
      </div>
    </div>
    <!-- 移动端头部菜单栏 -->
    <div :style="{ width: screenWidth + 'px' }" class="headertableClass" v-if="dialogHeaderVisible">
      <ul>
        <li @click="scrollToAnchor('contentjy')">走進疆域</li>
        <li @click="scrollToAnchor('product')">產品及解决方案</li>
        <li @click="scrollToAnchor('newsCenter')">新聞中心</li>
        <li @click="scrollToAnchor('footerF')">聯繫我們</li>
      </ul>
    </div>
    <div class="banner" id="banner">
      <el-carousel trigger="click" :loop="true" :height="screenHeight" :autoplay="true" @change="handleBanner"
        :initial-index="0" :interval="5000">
        <el-carousel-item v-for="(item, index) in bgImg" :key="index">
          <div class="bannerT">
            <p v-html="item.title"></p>
            <p v-html="item.content"></p>
            <button class="bannerB" @click="handleLook(item)">
              了解更多
              <img v-if="screenWidth > 655" style="
                  width: 24px;
                  height: 24px;
                  line-height: 24px;
                  position: absolute;
                  bottom: 20px;
                  left: 130px;
                " :src="arrowbanner" alt="" />
            </button>
          </div>
          <img style="width: 100%; height: 100%" :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contentjy" id="contentjy">
      <JYcontent></JYcontent>
    </div>
    <div class="product" id="product">
      <product1 :screenWidth="screenWidth"></product1>
    </div>
    <div class="system" id="system">
      <system :screenWidth="screenWidth"></system>
    </div>
    <div class="strategyLayout" id="system">
      <strategyLayout></strategyLayout>
    </div>
    <div class="newsCenter" id="newsCenter">
      <newsCenter :size="size" :screenWidth="screenWidth"></newsCenter>
    </div>
    <div class="footerF" id="footerF">
      <ul>
        <li>
          <p class="fTclass" @click="scrollToAnchor('banner')">首頁</p>
        </li>
        <li>
          <p class="fTclass" @click="scrollToAnchor('product')">
            產品及解決方案
          </p>
          <div>
            <p @click="scrollToAnchor('product')" class="setbgColor">
              終端產品
            </p>
            <p @click="scrollToAnchor('product')" class="setbgColor">
              解決方案
            </p>
          </div>
        </li>
        <li>
          <p class="fTclass" @click="scrollToAnchor('contentjy')">走進疆域</p>
        </li>
        <li>
          <p class="fTclass" @click="scrollToAnchor('newsCenter')">新聞中心</p>
        </li>
        <li>
          <p class="fTclass">聯繫我們</p>
          <div>
            <p>電子信箱：service@jy-meditech.com</p>
            <p>週一至週五8:30-17:30</p>
            <button @click="handleContact">聯繫我們</button>
          </div>
        </li>
      </ul>
    </div>

    <!-- 聯係我們彈出框 -->
    <el-dialog v-model="centerDialogVisible" title="聯繫我們" :width="592" custom-class="footerStyle"
      :close-on-click-modal="false" :show-close="true" @close="handleClose" align-center>
      <div class="setDialogTips">
        <p>電子信箱：service@jy-meditech.com</p>
        <p>您也可以在下方留下您的聯繫方式，我們會盡快與您聯繫</p>
      </div>
      <el-form :model="form" :rules="rules" ref="form" label-width="auto" style="max-width: 600px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="請輸入姓名" />
        </el-form-item>
        <el-form-item label="公司名稱" prop="companyName">
          <el-input v-model="form.companyName" placeholder="請輸入公司名稱" />
        </el-form-item>
        <el-form-item label="手機號碼" prop="phoneNumber">
          <el-input v-model="form.phoneNumber" placeholder="請輸入手機號碼" />
        </el-form-item>
        <el-form-item label="電子信箱" prop="emaril">
          <el-input v-model="form.email" placeholder="請輸入電子信箱" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button class="setSubmitBtn" :type="checkInput() ? 'primary' : 'info'"
            :disabled="checkInput() ? false : true" @click="handleSubmit()">
            提交
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import img1 from "@/assets/icon/banner/1920/heroimage_1.png";
import img2 from "@/assets/icon/banner/1920/heroimage_2.png";
import img3 from "@/assets/icon/banner/1920/heroimage_3.png";
import img4 from "@/assets/icon/banner/1440/heroimage_1.png";
import img5 from "@/assets/icon/banner/1440/heroimage_2.png";
import img6 from "@/assets/icon/banner/1440/heroimage_3.png";
import img7 from "@/assets/icon/banner/1024/heroimage_1.png";
import img8 from "@/assets/icon/banner/1024/heroimage_2.png";
import img9 from "@/assets/icon/banner/1024/heroimage_3.png";
import img10 from "@/assets/icon/banner/768/heroimage_1.png";
import img11 from "@/assets/icon/banner/768/heroimage_2.png";
import img12 from "@/assets/icon/banner/768/heroimage_3.png";
import img13 from "@/assets/icon/banner/375/heroimage_1.png";
import img14 from "@/assets/icon/banner/375/heroimage_2.png";
import img15 from "@/assets/icon/banner/375/heroimage_3.png";
import logo from "@/assets/icon/logo.png";
import arrowbanner from "@/assets/icon/arrow/24/arrow.png";
import whiteLeftArrow from "@/assets/icon/arrow/48/arrowleft_white.png";
import whiteRightArrow from "@/assets/icon/arrow/48/arrowright_white.png";
import blueLeftArrow from "@/assets/icon/arrow/48/arrowleft_blue.png";
import blueRightArrow from "@/assets/icon/arrow/48/arrowright_blue.png";
import burger_pad from "@/assets/icon/burger_pad.png";
import burger_phone from "@/assets/icon/burger.png";
import burgerclose from "@/assets/icon/burgerclose2.png";
import JYcontent from "@/components/contentJY.vue";
import product1 from "@/components/product1.vue";
import system from "@/components/system.vue";
import strategyLayout from "@/components/strategyLayout.vue";
import newsCenter from "@/components/newsCenter.vue";
import { getNews, submitInfo, getBanner } from "@/api/index.js";
export default {
  data() {
    return {
      logo: logo,
      arrowbanner: arrowbanner,
      whiteLeftArrow: whiteLeftArrow,
      whiteRightArrow: whiteRightArrow,
      blueLeftArrow: blueLeftArrow,
      blueRightArrow: blueRightArrow,
      bgImg: [],
      bgText: [],
      centerDialogVisible: false,
      form: {
        name: "",
        companyName: "",
        phoneNumber: "",
        email: "",
      },
      rules: {
        phoneNumber: [
          { required: true, message: "請輸入手機號碼", trigger: "blur" },
        ],
      },
      screenWidth: null, //屏幕宽度
      screenHeight: null,
      isPhone: false, //判断是否是移动端
      isnull: false,
      size: 0,
      propImg: "",
      propCloseImg: burgerclose,
      style: {
        background: "",
        borderBottom: "",
      },
      dialogHeaderVisible: false,
    };
  },
  components: {
    JYcontent,
    product1,
    system,
    strategyLayout,
    newsCenter,
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // console.log(this.screenWidth);
      })();
    };
    window.addEventListener("scroll", this.setBgScroll);
    this.getImgList();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setBgScroll);
  },
  watch: {
    screenWidth: {
      handler: function (newval, oldval) {
        this.getImgList();
        if (newval < 655) {
          console.log("屏幕宽度小于376px");
          this.isPhone = true;
          this.size = 0;
          this.screenHeight = 680 + "px";
          this.propImg = burger_phone;
        } else if (newval > 655 && newval < 1023) {
          this.screenHeight = 980 + "px";
          this.isPhone = true;
          this.size = 1;
          this.propImg = burger_pad;
        } else if (newval > 1023 && newval < 1439) {
          this.screenHeight = 768 + "px";
          this.isPhone = false;
          this.size = 2;
        } else {
          this.screenHeight = 900 + "px";
          this.isPhone = false;
          this.size = 3;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleContact() {
      if (this.screenWidth > 655) {
        this.centerDialogVisible = true;
      } else {
        this.$router.push({ path: "/info" });
        this.centerDialogVisible = false;
      }
    },
    checkInput() {
      return (
        this.form.name ||
        this.form.companyName ||
        this.form.email ||
        this.form.phoneNumber
      );
    },
    handleSubmit() {
      console.log(this.$refs);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          console.log(this.form);
          const data1 = {
            Uname: this.form.name,
            email: this.form.email,
            phone: this.form.phoneNumber,
            company: this.form.companyName,
          };
          const { code, msg } = await submitInfo(data1);
          if (code === 200) {
            this.$message({
              type: 'success',
              offset: 300,
              duration: 2000,
              center: true,
              message: '提交成功'
            })
            setTimeout(() => {
              this.centerDialogVisible = false;
              this.$refs.form.resetFields();
              this.clearForm()
            }, 2000)
          } else {
            this.$message({
              type: 'info',
              message: '提交失敗，請重嘗試',
              offset: 300,
              duration: 2000,
              center: true,
            })
          }
        } else {
          return false;
        }
      });
    },
    // 清空表单
    clearForm() {
      this.form.name = '';
      this.form.companyName = '';
      this.form.phoneNumber = '';
      this.form.email = '';
    },

    scrollToAnchor(anchor) {
      const el = document.getElementById(anchor);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleBanner(val) {
      // 处理最后一张图片时候，背景色为白色
      if (val === 2) {
        // console.log(document.querySelector(".el-carousel__arrow--right").style);
        document.querySelector(
          ".el-carousel__arrow--left"
        ).style.cssText = `background-image: url(${this.whiteLeftArrow}) !important`;
        document.querySelector(
          ".el-carousel__arrow--right"
        ).style.cssText = `background-image: url(${this.whiteRightArrow}) !important`;
      } else {
        document.querySelector(
          ".el-carousel__arrow--left"
        ).style.cssText = `background-image: url(${this.blueLeftArrow}) !important`;
        document.querySelector(
          ".el-carousel__arrow--right"
        ).style.cssText = `background-image: url(${this.blueRightArrow}) !important`;
      }
    },

    // 获取轮播图
    async getImgList() {
      let newWidth = this.screenWidth;
      this.bgImg = [
        {
          imgId: 1,
          img:
            newWidth > 1441
              ? img1
              : newWidth < 1441 && newWidth > 1025
                ? img4
                : newWidth < 1025 && newWidth > 769
                  ? img7
                  : newWidth < 769 && newWidth > 375
                    ? img10
                    : img13,
        },
        {
          imgId: 2,
          img:
            newWidth > 1441
              ? img2
              : newWidth < 1441 && newWidth > 1025
                ? img5
                : newWidth < 1025 && newWidth > 769
                  ? img8
                  : newWidth < 769 && newWidth > 375
                    ? img11
                    : img14,
        },
        {
          imgId: 3,
          img:
            newWidth > 1441
              ? img3
              : newWidth < 1441 && newWidth > 1025
                ? img6
                : newWidth < 1025 && newWidth > 769
                  ? img9
                  : newWidth < 769 && newWidth > 375
                    ? img12
                    : img15,
        },]
      if (this.bgText.length === 0) {
        this.getTextList()
      } else {
        this.handleTextLength()
      }
    },
    // 獲取輪播圖文字列表
    async getTextList() {
      let { code, msg, data } = await getBanner()
      if (code === 200) {
        this.bgText = data
        this.handleTextLength()
      } else {
        this.$message({
          type: 'warning',
          message: msg
        });
      }
    },
    // 處理輪播圖獲取到的數據格式
    handleTextLength() {
      let newImgList = this.bgImg
      let list = this.bgText
      this.bgImg = newImgList.flatMap(e =>
        list
          .filter(v => e.imgId === v.displayCount)
          .map(v => ({ ...e, ...v }))
      );
    },

    handleLook(val) {
      window.open(val.linkUrl, "_blank");
    },

    setBgScroll() {
      let scrollTop =
        window.pageXOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop) {
        this.style.background = this.dialogHeaderVisible
          ? "rgba(255, 255, 255, 0.8)"
          : "#ffffff";
        this.style.borderBottom = this.dialogHeaderVisible ? 0 : "2px solid #F0F0F0";
      } else {
        this.style.background = this.dialogHeaderVisible
          ? "rgba(255, 255, 255, 0.8)"
          : "rgba(255, 255, 255, 0.5)";
        this.style.borderBottom = 0;
      }
    },

    handleHeaderTable() {
      this.dialogHeaderVisible = true;
      this.style.background = "rgba(255, 255, 255, 0.8)";
    },
    handleCloseFun() {
      this.dialogHeaderVisible = false;
    },
    // 关闭联系我们弹出框
    handleClose() {
      this.$refs.form.resetFields();
      this.clearForm()
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="less">
.headerH {
  width: 100%;
  // height: 30px;
  padding: 24px 0px;
  position: fixed;
  z-index: 99;
  background: rgba(255, 255, 255, 0.5);

  .flewWidth {
    width: 80%;
    margin: 0 auto;
  }

  .right-H ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    li {
      list-style: none;
      margin: 0 0 0 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #0053a6;
      height: 16px;
      padding: 6px 0;
      border-bottom: 2px solid transparent;
    }

    li:hover,
    li:focus {
      cursor: default;
      font-weight: 700;
      border-bottom: 2px solid #0052a4;
    }
  }
}

.banner {
  .el-carousel__arrow {
    // background-color: rgba(0, 82, 164, 0.4);
    // color: #0052a4;
    font-size: 20px !important;
    width: 48px !important;
    height: 48px !important;

    i {
      display: none;
    }
  }

  .el-carousel__arrow--left {
    background-image: url("@/assets/icon/arrow/48/arrowleft_blue.png") !important;
  }

  .el-carousel__arrow--right {
    background-image: url("@/assets/icon/arrow/48/arrowright_blue.png") !important;
  }

  .bannerT {
    position: absolute;
    top: 35%;
    left: 8%;
    text-align: left;
    color: #0052a4;

    p:first-child {
      font-size: 60px;
      line-height: 140%;
      font-weight: 500;
    }

    p:nth-child(2) {
      font-size: 40px;
      line-height: 140%;
      font-weight: 500;
    }
  }

  .el-carousel__button {
    width: 12px !important;
    height: 12px !important;
    border-radius: 12px;
    background-color: #0052a4;
    opacity: 0.2;
  }

  .el-carousel__indicator {
    padding: 32px 12px !important;
  }

  .el-carousel__indicator.is-active button {
    background-color: #0052a4;
  }

  .bannerB {
    width: 200px;
    height: 60px;
    border-radius: 200px;
    padding: 16px 32px 16px 40px;
    margin-top: 20px;
    background-color: #ef7f1b;
    border: 0;
    // box-shadow: 1px 10px 12px 0 #d0d7e2;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 500;
    text-align: justify;

    .el-icon {
      // background-color: red !important;
      color: #ffffff !important;
      line-height: 24px;

      svg {
        position: absolute;
        top: 3px;
        left: 8px;
      }
    }
  }
}

.footerF {
  height: 460px;
  background: #0d263f;
  color: #ffffff;

  ul {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    top: 100px;
    list-style: none;
    width: 80%;
    margin: 0 auto;

    li {
      .fTclass {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-align: left;
      }

      div {
        margin-top: 32px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          text-align: left;
        }

        p:nth-child(2) {
          margin-top: 16px;
        }

        button {
          padding: 6px 0px 5px 24px;
          border-radius: 200px;
          width: 120px;
          height: 40px;
          border: 1px solid #ef7f1b;
          background-color: #ef7f1b;
          margin-top: 10px;
          position: relative;
          left: -70px;
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          background-image: url("@/assets/icon/arrow/24/contactus3x.png");
          background-size: 24px;
          background-repeat: no-repeat;
          background-position-y: center;
          background-position-x: 10px;
        }
      }
    }
  }
}

.el-dialog {
  width: 592px !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  .el-dialog__header {
    background-color: #0052a4;
    color: #ffffff;
    width: 472px;
    height: 36px;
    line-height: 36px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 12px 60px;

    .el-dialog__title {
      line-height: 20px !important;
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      font-family: "Noto Sans CJK TC";
    }

    .el-dialog__headerbtn {
      width: 36px;
      height: 36px;
      background-image: url("@/assets/icon/arrow/24/close.png");
      background-repeat: no-repeat;
      background-size: 36px;
      position: absolute;
      right: 16px;
      top: 10px;

      i {
        display: none;
      }
    }
  }

  .el-dialog__body {
    width: 552px;
    background: #ffffff;
    padding-top: 0px;

    .setDialogTips {
      text-align: center;
      margin: 10px auto;
      font-family: "Noto Sans CJK TC";

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 25.6px;
        color: #333333;
      }
    }
  }

  .el-form {
    max-width: 360px !important;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0px);

    .el-form-item {
      display: flex !important;
      flex-direction: column !important;
      margin-top: 24px;

      .el-form-item__error {
        top: 120%;
      }

      .el-input--small {
        height: 36px !important;

        .el-input__inner {
          height: 36px;
          line-height: 36px;
          padding: 10px 12px;
          border-radius: 4px;
          border: 1px solid #c8c6c9;
          margin-top: 8px;
          font-family: "Noto Sans CJK TC";
          font-size: 16px;
          font-weight: 400;
          caret-color: #0052A4;

        }

        .el-input__inner:focus,
        .el-input__inner:hover {
          border-color: #0052a4;
        }
      }

      .el-form-item__label {
        width: 100px !important;
        display: inline !important;
        font-weight: 400 !important;
        font-size: 16px;
        color: #333333;
        text-align: left;
      }
    }
  }

  .el-dialog__footer {
    text-align: center !important;
    width: 592px;
    background: #ffffff;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;

    .el-button--primary {
      background-color: #0052a4 !important;
    }

    .el-button {
      font-size: 18px;
      font-weight: 500;
      font-family: "Noto Sans CJK TC";
      line-height: 18px;
    }

    .setSubmitBtn {
      width: 360px;
      height: 53px;
      border-radius: 8px;
      margin: 20px auto;
    }

    .el-button--info {
      width: 360px;
      height: 53px;
      border-radius: 8px;
      margin: 20px auto;
      background-color: #828282 !important;
      border: 0;
    }
  }
}

.contentjy,
.product,
.system,
.strategyLayout,
.newsCenter {
  width: 100%;
  // height: 900px;
}

@media only screen and (min-width: 1919px) {
  .headerH {
    .flewWidth {
      width: 1152px;
      margin: 0 auto;

      .left-H {
        img {
          width: 142px;
          height: 30px;
        }
      }
    }
  }

  .banner {
    .bannerT {
      width: 915px;
      position: absolute;
      top: 50%;
      left: 44%;
      transform: translate(-50%, -50%);
    }

    .el-carousel {
      img {
        object-fit: cover;
        object-position: 70%;
      }

      ul {
        li {
          padding: 32px 24px !important;
        }
      }
    }

    .bannerB {
      img {
        position: absolute !important;
        bottom: 20px !important;
        left: 145px !important;
      }
    }
  }

  .contentjy {
    background-image: url("@/assets/icon/bg/1920/bgwave1_web1920.png");
    background-repeat: no-repeat;
    background-size: 100% 792px;
  }

  .product {
    background-image: url("@/assets/icon/bg/1920/bgwave2_web1920.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 792px;
  }

  .system {
    background-image: url("@/assets/icon/bg/1920/bgwave3_web1920.png");
    background-repeat: no-repeat;
    background-size: 100% 236px;
  }

  .strategyLayout {
    background-image: url("@/assets/icon/bg/1920/bgwave4_web1920.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 792px;
  }

  .footerF {
    ul {
      width: 1440px;
      margin: 0 auto;

      li {
        div {
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1439px) and (max-width: 1919px) {
  .contentjy {
    background-image: url("@/assets/icon/bg/1440/bgwave1_web1440.png");
    background-repeat: no-repeat;
    background-size: 100% 792px;
  }

  .product {
    background-image: url("@/assets/icon/bg/1440/bgwave2_web1440.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 792px;
  }

  .system {
    background-image: url("@/assets/icon/bg/1440/bgwave3_web1440.png");
    background-repeat: no-repeat;
    background-size: 100% 236px;
  }

  .strategyLayout {
    background-image: url("@/assets/icon/bg/1440/bgwave4_web1440.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 792px;
  }

  .headerH {
    .flewWidth {
      width: 1152px;
      margin: 0 auto;

      .left-H {
        img {
          width: 142px;
          height: 30px;
        }
      }
    }
  }

  .banner {
    .bannerT {
      width: 915px;
      position: absolute;
      top: 50%;
      left: 42%;
      transform: translate(-50%, -50%);
    }

    .el-carousel {
      img {
        object-fit: cover;
        object-position: 70%;
      }
    }

    .bannerB {
      img {
        position: absolute !important;
        bottom: 20px !important;
        left: 145px !important;
      }
    }
  }

  .footerF {
    ul {
      li {
        div {
          button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1023px) and (max-width: 1439px) {
  .headerH {
    .flewWidth {
      width: 80%;
      margin: 0 auto;
    }

    .left-H {
      img {
        width: 142px;
        height: 30px;
      }
    }
  }

  .banner {
    height: 768px;

    .el-carousel__button {
      width: 8px !important;
      height: 8px !important;
      border-radius: 8px;
    }

    .el-carousel__arrow {
      display: none;
    }

    .el-carousel {
      height: 100%;

      img {
        height: 768px;
        object-fit: cover;
        object-position: 70%;
      }
    }

    .bannerT {
      left: 10%;

      p:first-child {
        font-size: 40px;
        line-height: 40px;
      }

      p:nth-child(2) {
        font-size: 28px;
        line-height: 28px;
        margin-top: 15px;
      }
    }

    .bannerB {
      font-size: 20px;
      line-height: 20px;
      width: 184px;
    }
  }

  .contentjy {
    background-image: url("@/assets/icon/bg/1024/bgwave1_pad1024.png");
    background-repeat: no-repeat;
    background-size: 100% 560px;
  }

  .product {
    background-image: url("@/assets/icon/bg/1024/bgwave2_pad1024.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 562px;
  }

  .system {
    background-image: url("@/assets/icon/bg/1024/bgwave3_pad1024.png");
    background-repeat: no-repeat;
    background-size: 100% 168px;
  }

  .strategyLayout {
    background-image: url("@/assets/icon/bg/1024/bgwave4_pad1024.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 560px;
  }

  .footerF {
    height: 392px;

    ul {
      top: 50px;

      li {
        margin: 25px auto;

        .fTclass {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
        }

        div {
          margin-top: 10px;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            padding-top: 10px;
          }

          button {
            position: relative;
            left: -54px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
  .headerH {
    .flewWidth {
      width: 90%;
      margin: 0 auto;
    }

    .left-H {
      img {
        width: 112px;
      }
    }
  }

  .banner {
    .el-carousel__button {
      width: 8px !important;
      height: 8px !important;
      border-radius: 8px;
    }

    .el-carousel__arrow {
      display: none;
    }

    .el-carousel {
      img {
        height: 980px;
        object-fit: cover;
        object-position: 79%;
      }
    }

    .bannerT {
      top: 45%;
      left: 5%;

      p:first-child {
        font-size: 40px;
        line-height: 40px;
      }

      p:nth-child(2) {
        font-size: 28px;
        margin-top: 15px;
      }
    }

    .bannerB {
      font-size: 20px;
      line-height: 20px;
      width: 184px;
    }
  }

  .contentjy {
    background-image: url("@/assets/icon/bg/768/bgwave1_pad768.png");
    background-repeat: no-repeat;
    background-size: 100% 492px;
  }

  .product {
    background-image: url("@/assets/icon/bg/768/bgwave2_pad768.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 492px;
  }

  .system {
    background-image: url("@/assets/icon/bg/768/bgwave3_pad768.png");
    background-repeat: no-repeat;
    background-size: 100% 166px;
  }

  .strategyLayout {
    background-image: url("@/assets/icon/bg/768/bgwave4_pad768.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 408px;
  }

  .footerF {
    height: 768px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 70px;

      li:last-child {
        border-bottom: 0;
      }

      li {
        padding: 32px 0;
        border-bottom: 1px solid rgba(130, 157, 188, 0.25);
        width: 130%;

        .fTclass {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
        }

        div {
          margin-top: 20px;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            padding-top: 10px;
          }

          .setbgColor {
            color: #a0cfff;
            margin: 15px;
          }

          button {
            padding: 6px 0px 5px 24px;
            border-radius: 200px;
            width: 120px;
            height: 40px;
            border: 1px solid #ef7f1b;
            background-color: #ef7f1b;
            margin-top: 30px;
            position: relative;
            left: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .headertableClass {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;
    top: 88px;

    ul {
      li {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #0053a6;
        padding: 20px 40px;
        border-top: 0.5px solid #DEE4EC
      }

      li:focus,
      li:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .headerH {
    padding: 12px 0;

    .flewWidth {
      width: 90%;

      .left-H {
        img {
          width: 112px;
        }
      }
    }
  }

  .el-popper {
    width: 125px;
  }

  .banner {
    height: 680px;

    .el-carousel__button {
      width: 8px !important;
      height: 8px !important;
      border-radius: 8px;
    }

    .el-carousel__arrow {
      display: none;
    }

    .el-carousel {
      height: 100%;
      overflow-y: hidden;

      img {
        height: 680px;
        object-fit: cover;
        object-position: 73%;
      }
    }

    .bannerT {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      p:first-child {
        font-size: 32px;
        line-height: 44.8px;
      }

      p:nth-child(2) {
        font-size: 18px;
        line-height: 28px;
        margin-top: 10px;
      }
    }

    .bannerB {
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      width: 160px;
    }
  }

  .contentjy {
    background-image: url("@/assets/icon/bg/375/bgwave1_phone375.png");
    background-repeat: no-repeat;
    background-size: 100% 240px;
  }

  .product {
    background-image: url("@/assets/icon/bg/375/bgwave2_phone375.png");
    background-repeat: no-repeat;
    background-size: 100% 240px;
    background-position: bottom;
  }

  .system {
    background-image: url("@/assets/icon/bg/375/bgwave3_phone375.png");
    background-repeat: no-repeat;
    background-size: 100% 80px;
  }

  .strategyLayout {
    background-image: url("@/assets/icon/bg/375/bgwave4_phone375.png");
    background-repeat: no-repeat;
    background-size: 100% 200px;
    background-position: bottom;
  }

  .footerF {
    height: 688px;
    position: relative;
    bottom: 0;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 35px;

      li:last-child {
        border-bottom: 0;
      }

      li {
        padding: 25px 0;
        border-bottom: 1px solid rgba(130, 157, 188, 0.25);
        width: 130%;

        .fTclass {
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
        }

        div {
          margin-top: 10px;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            padding-top: 10px;
          }

          p:nth-child(2) {
            margin-top: 8px;
          }

          .setbgColor {
            color: #a0cfff;
            margin: 15px;
          }

          button {
            padding: 6px 0px 5px 24px;
            border-radius: 200px;
            width: 120px;
            height: 40px;
            border: 1px solid #ef7f1b;
            background-color: #ef7f1b;
            margin-top: 30px;
            position: relative;
            left: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .headertableClass {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 1;
    top: 64px;

    ul {
      li {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #0053a6;
        padding: 16px 20px;
        border-top: 0.5px solid #DEE4EC
      }
    }
  }
}
</style>
